import React, { Component } from 'react';

import { Video } from 'elements/VideoNew/Video';
export class Showreel extends Component {
  render() {
    return (
      <Video
        playsInline={true}
        muted={true}
        autoPlay={true}
        src={'/showreel/showreel_new_music_text.h264.mp4'}
        poster={'/showreel/showreel_new.webp'}
      />
    );
  }
}
