import React, { Component } from 'react';
import { Feed, Projects, Seo } from 'components';
import { Hello } from 'components/HelloMain';
import { About } from 'components/AboutMain';
import { Showreel } from 'components/Showreel';
import { graphql } from 'gatsby';

class Main extends Component {
  render() {
    let list = this.props.data.site.siteMetadata.main;

    return (
      <>
        <Seo
          title="Digital-агентство Nimax"
          description="Стратегия и digital. Помогаем стартовать и меняться. Объединяем отраслевой опыт клиента и нашу digital-экспертизу. Прививаем компаниям новые подходы к работе и digital-технологии, развиваем рынок и специалистов."
        />
        <Hello video={['/hello/header-big.h264.mp4']} />
        <Showreel />
        <Projects category="main" />
        <About />
      </>
    );
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        main
      }
    }
  }
`;

export default Main;
